import { useEffect, useState } from 'react';
import styles from './index.module.scss';
// import { useTranslation } from '@/lib/providers/i18n';-i18next'
import classNames from 'classnames/bind';

const Banner = ({
  appearance = 'warning',
  message = '',
  permanent = false,
  inline = false,
}) => {
  const [hidden, setHidden] = useState(false);
  const [fading, setFading] = useState(false);
  const [render, setRender] = useState(true);
  const cx = classNames.bind(styles)('container', appearance, {
    hidden: hidden,
    fading: fading,
    inline: inline,
  });

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setFading(false);
        setHidden(false);
        setRender(true);
      }, 1);

      if (!permanent) {
        setTimeout(() => setFading(true), 7000);
        setTimeout(() => setHidden(true), 8000);
        setTimeout(() => setRender(false), 9000);
      }
    }
  }, [message, permanent]);

  return (
    <div className={cx}>
      {render && <div className={styles.bar}>{message}</div>}
    </div>
  );
};

export default Banner;
