import classNames from 'classnames/bind';
import Link from 'next/link';

import styles from './index.module.scss';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  text?: string;
  href?: string;
  download?: boolean;
  type?: string;
  provider?: 'google' | 'facebook' | 'apple' | 'microsoft';
  onClick?: (event?: any) => void;
  [key: string]: any;
}

const Button = ({
  children,
  text,
  href,
  download,
  type,
  provider,
  onClick,
  ...rest
}: ButtonProps) => {
  const cx = classNames.bind(styles);
  const classes = cx('button', type, provider, { provider: !!provider }, rest);

  if (type && type === 'submit') {
    return (
      <button type="submit" onClick={onClick} className={classes}>
        <span className={cx('inside')}>{text || children || ''}</span>
      </button>
    );
  }

  if ((href === '#' || !href) && !!onClick) {
    return (
      <a className={classes} onClick={onClick}>
        <span className={cx('inside')}>{text || children || ''}</span>
      </a>
    );
  }
  if (!href && !onClick) {
    return (
      <span className={classes}>
        <span className={cx('inside')}>{text || children || ''}</span>
      </span>
    );
  }
  if (download && !!href) {
    return (
      <a className={classes} href={href} download>
        <span className={cx('inside')}>{text || children || ''}</span>
      </a>
    );
  }

  return (
    <Link href={href!} className={classes}>
      <span className={cx('inside')}>{text || children || ''}</span>
    </Link>
  );
};

export default Button;
