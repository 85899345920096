import styles from './index.module.scss';

const Logo = ({ mark }: { mark?: boolean }) => {
  return (
    <div className={styles.logo}>
      <i />
      {!mark && <span> Coverstories</span>}
    </div>
  );
};

export default Logo;
