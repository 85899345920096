import { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';

import Banner from '@/elements/Banner';
import Button from '@/elements/Button';
import Logo from '@/elements/Logo';
import { useSupabase } from '@/hooks/useSupabase';
import { getURL } from '@/lib/getUrl';
import { useTranslation } from '@/lib/providers/i18n';
import { Provider } from '@supabase/supabase-js';
import classNames from 'classnames/bind';
import { useRouter, useSearchParams } from 'next/navigation';

const Login = ({
  loginAction,
  otpAction,
}: {
  loginAction: (formData: FormData) => Promise<void>;
  otpAction: (formData: FormData) => Promise<void>;
}) => {
  const supabase = useSupabase();
  const { t } = useTranslation();
  const form = useRef<HTMLFormElement>(null);
  const searchParams = useSearchParams();
  const [email, setEmail] = useState(searchParams.get('email') ?? '');
  const [otpCode, setOtpCode] = useState('');
  const [errorMessage, setErrorMsg] = useState(
    searchParams.get('error') ? 'message.error.generic' : '',
  );
  const [successMessage, setSuccessMsg] = useState(
    searchParams.has('email') ? 'log-in.sent' : '',
  );
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const cx = classNames.bind(styles);
  const router = useRouter();

  useEffect(() => {
    if (searchParams.get('sent') === 'true') {
      setSent(true);
    }
  }, [searchParams]);

  const handleClick = (e: MouseEvent) => {
    if (loading) e.preventDefault();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpCode(e.target.value);
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();

    const interval = setInterval(() => {
      if (inputRef.current) {
        setEmail(inputRef.current.value);
        clearInterval(interval);
      }
    }, 100);
  });

  const handleOAuth = async (provider: Provider, options?: any) => {
    let { error } = await supabase.auth.signInWithOAuth({
      provider,
      options: { ...options, redirectTo: getURL() },
    });

    if (error) {
      setErrorMsg(error.message);
    }
  };

  return (
    <form
      className={cx('container')}
      ref={form}
      action={!sent ? loginAction : otpAction}
    >
      <div className={cx('content')}>
        <div className={cx('header')}>
          <Logo />
        </div>

        <div className={cx('banner')}>
          {!!errorMessage && errorMessage !== '' && (
            <Banner
              appearance="error"
              message={t(errorMessage)}
              permanent={true}
            />
          )}
          {!!successMessage && successMessage !== '' && (
            <Banner
              appearance="success"
              message={t(successMessage)}
              permanent={true}
            />
          )}
        </div>
        <div className={cx('inputs')}>
          {!sent && (
            <>
              <label className={cx('input')}>
                <span className={cx('label')}>{t('email')}</span>
                <input
                  ref={inputRef}
                  type="email"
                  name="email"
                  placeholder={t('email-placeholder')}
                  autoComplete="email"
                  value={
                    searchParams.has('email')
                      ? searchParams.get('email')
                      : email
                  }
                  onChange={onChange}
                  autoFocus
                />
              </label>
              <div className={cx('button')}>
                <Button
                  text={t('log-in.magic')}
                  loading={loading}
                  type="submit"
                  primary
                  full
                  onClick={() => setLoading(true)}
                />
              </div>
              <div className={cx('separator')}>
                <span>{t('or')}</span>
              </div>

              <div className={cx('button')}>
                <Button
                  type="button"
                  primary
                  full
                  provider="google"
                  onClick={() => handleOAuth('google')}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      fill="#4285F4"
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    />
                    <path
                      fill="#34A853"
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    />
                    <path
                      fill="#FBBC05"
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    />
                    <path
                      fill="#EA4335"
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    />
                    <path fill="none" d="M1 1h22v22H1z" />
                  </svg>
                  {t('log-in.google')}
                </Button>
              </div>
              <div className={cx('button')}>
                <Button
                  type="button"
                  primary
                  full
                  provider="microsoft"
                  onClick={() =>
                    handleOAuth('azure', { scopes: 'email offline_access' })
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
                    <title>MS-SymbolLockup</title>
                    <rect x="1" y="1" width="9" height="9" fill="#f25022" />
                    <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
                    <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
                    <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
                  </svg>
                  {t('log-in.microsoft')}
                </Button>
              </div>
              <div
                className={cx('footer')}
                dangerouslySetInnerHTML={{ __html: t('log-in.footer') }}
              />
            </>
          )}
          {!!sent && (
            <>
              <label className={cx('input')}>
                <input type="hidden" name="email" value={email} />
                <span className={cx('label')}>{t('one-time-code')}</span>
                <input
                  type="text"
                  name="otp"
                  autoComplete="one-time-code"
                  value={otpCode}
                  onChange={onOtpChange}
                />
              </label>

              <div className={cx('button')}>
                <Button
                  text={t('login')}
                  loading={loading}
                  type="submit"
                  primary
                  full
                />
              </div>

              <div
                className={cx('footer')}
                dangerouslySetInnerHTML={{ __html: t('log-in.footer') }}
              />
            </>
          )}
        </div>
      </div>

      <div className={cx('legal')}>
        <a
          href="https://www.coverstories.co/legal/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
        <a
          href="https://www.coverstories.co/legal/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Statement
        </a>
      </div>
    </form>
  );
};

export default Login;
